/******************************************************************
STYLESHEET: LOADING

******************************************************************/

.loading {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $c12-grey;
    transition: all 0.1s;
    z-index: 50;

    &.hidden {
        height: 0;

        > span {
            display: none;
        }
    }

    &__icon {
        width: 100px;
        height: 100px;
        display: inline-block;
        position: absolute;
        top: 35%;
        left: 50%;
        margin-left: -50px;
        background-image: url('../img/mumbl-logo.svg');
        background-position: center center;
        background-size: cover;

        &.pulse {
            animation: pulse 1s infinite;
        }
    }
}
