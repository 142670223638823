/******************************************************************
STYLESHEET: FOOTER

******************************************************************/

.footer {
    color: $c-white;
    font-weight: 700;
    text-align: center;
    margin: 2em 0 1em;

    &__copyright {
        opacity: 0.3;
    }

    &__link {
        color: $c-white;
        font-family: $f-base;
        text-decoration: none;
        border-bottom: 2px solid $c-white;
        opacity: 0.3;
        transition: opacity 0.2s;

        &:hover,
        &:focus {
            outline: none;
            opacity: 1;
        }
    }
}