/******************************************************************
STYLESHEET: SETTINGS

******************************************************************/

.settings {
    color: $c-white;
    max-height: 660px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 5.5em 0 1em;
    padding: 0 2em 1.5em;

    @include small {
        margin: 0;
    }

    &-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 1.5em 0;
        border-radius: 0 5px 5px 0;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        transition: all 0.2s;
        transform: translateX(-150%);
        z-index: 25;

        @include small {
            top: 11em;
            width: 300px;
            height: auto;
        }

        &:hover {
            box-shadow: 1px 1px 70px rgba(0, 0, 0, 0.2);
        }

        &.active {
            transform: translateX(0);
        }
    }

    &__sub-heading {
        margin-top: 0;
    }

    &__block {
        padding: 0.7em 0;

        &--inner {
            position: relative;
            margin: 0.7em 0;
        }
    }

    &__input {
        opacity: 0;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;

        &:checked+.settings__label:after {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            background-image: url('../img/icon-check.svg');
            background-size: cover;
            background-position: center center;
            position: absolute;
            top: 0.15em;
            left: -1.7em;
            font-size: 0.9rem;
            font-weight: 700;
            color: $c-white;
        }
    }

    &__label {
        margin-left: 1.7em;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: -1.7em;
            background-color: $c-white;
            border-radius: 2px;
            opacity: 0.3;
        }
    }

    &__submit {
        margin-top: 1em;
        padding: 0.7em 1.3em;
        border: none;
        border-radius: 2px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
        transition: all 0.2s;
        font-weight: 700;

        &:focus {
            outline: 0;
        }

        @include medium {

            &:hover {
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
            }
        }
    }
}
