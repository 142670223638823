/******************************************************************
STYLESHEET: VARIABLES

    > COLORS
    > FONTS
    > GRID_GAPS
    > BREAKPOINTS

******************************************************************/



/******************************************************************
	COLORS
******************************************************************/

	/* ====================================
		GREYSCALE
	==================================== */

	$c-black:  						#000000;
	$c1-grey:   					#111111;
	$c2-grey:   					#222222;
	$c3-grey:   					#333333;
	$c4-grey:   					#444444;
	$c5-grey:   					#555555;
	$c6-grey:   					#666666;
	$c7-grey:   					#777777;
	$c8-grey:   					#888888;
	$c9-grey:   					#999999;
	$c10-grey:   					#aaaaaa;
	$c11-grey:   					#bbbbbb;
	$c12-grey:   					#cccccc;
	$c13-grey:   					#dddddd;
	$c14-grey:   					#eeeeee;
	$c-white:  						#ffffff;


	/* ====================================
		PROJECT
	==================================== */

	$c1-main:						#55ADC1;

	$c1-second:						#BBC9D7;


	/* ====================================
		SPECIALS
	==================================== */

	$c1-accent: 					#879cb1;
	$c1-error:						#D45268;
	$c1-success:					#43BB99;


/******************************************************************
	FONTS
******************************************************************/

	/* ====================================
		INITIALIZE
	==================================== */

	$opensans:     'Open Sans', 'Helvetica', 'Arial', sans-serif;
	$merriweather: 'Merriweather', 'Times New Roman', serif;
	$lobster: 'Lobster', 'Helvetica Neue', serif;
	$montserrat: 'Montserrat', 'Helvetica Neue', serif;


	/* ====================================
		DECLARATION
	==================================== */

	$f-base:    $montserrat;
	$f-heading: $lobster;
	$f-link:    $lobster;
	$f-text:    $montserrat;


/******************************************************************
	BREAKPOINTS
******************************************************************/

$w-small:  681px;
$w-medium: 961px;
$w-large:  1400px;