/******************************************************************
STYLESHEET: LIST

******************************************************************/

.list {
    list-style-type: none;
    max-height: 660px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 2em 0 1em;
    padding: 0 0.7em;
    font-size: 3rem;

    @include small {
        margin: 0;
    }

    &-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        padding: 1.5em 0;
        border-radius: 5px 0 0 5px;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        transition: all 0.2s;
        transform: translateX(150%);
        z-index: 25;

        @include small {
            top: 11em;
            width: 300px;
            height: auto;
        }

        &:hover {
            box-shadow: 1px 1px 70px rgba(0, 0, 0, 0.2);
        }

        &.active {
            transform: translateX(0);
        }
    }

    &__icon {
        // color: $c-white;
        display: inline-block;
        margin-right: 0.25em;
        position: absolute;
        left: -1.8em;
        top: 0;
        padding: 0 0.4em;
        border-radius: 50%;
    }

    &__item {
        color: $c-white;
        font-family: $f-text;
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;
        padding: 0.7em 1.8em 0.7em 0;
        position: relative;
        transition: all 0.2s;
        cursor: pointer;

        &[data-role="favorite"]:hover {

            .list__item__icon {
                cursor: pointer;
                background-image: url('../img/icon-x.svg') !important;
            }
        }

        &[data-role="placeholder"] {
            padding-right: 0;
            font-weight: 300;
        }

        &.fade-out {
            animation: fade-out 0.3s cubic-bezier(0, 1.11, 1, 1);
        }

        &__icon {
            display: inline-block;
            margin-right: 0.25em;
            position: absolute;
            top: 0.7em;
            width: 30px;
            height: 30px;
            background-position: center center;
            background-size: cover;

            &.btn--delete {
                right: 0;
                margin-right: 0;
            }

            &.btn--share {
                display: none;
                // right: 3.2em;
            }
        }
    }
}
