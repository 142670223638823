/******************************************************************
STYLESHEET: DEFAULTS

******************************************************************/

html {
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
    overflow-x: hidden;

    @include medium {
        font-size: 1.1em;
    }
}

body {
    font-family: $f-base;
    transition: background-color 0.2s;
    position: relative;
    overflow-x: hidden;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

::selection {
    background: $c1-accent;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $c11-grey;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}