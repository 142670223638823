/******************************************************************
STYLESHEET: TEXT

******************************************************************/


/******************************************************************
SPECIFIC
******************************************************************/

.text {
    font-family: $f-text;

    &--italic {
        font-style: italic;
    }

    &--light {
        font-weight: 300;
    }

    &--semibold {
        font-weight: 600;
    }

    &--bold {
        font-weight: 700;
    }
}