/******************************************************************
STYLESHEET: ANIMATIONS

******************************************************************/

@keyframes discard {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes rotate-out {
    0% {
        transform: scale(1) rotate(0);
    }
    37% {
        transform: scale(0.9) rotate(23deg);
    }
    67% {
        transform: scale(0.9) rotate(-23deg);
    }
    100% {
        transform: scale(1) rotate(0);
    }
}

@keyframes rotate-in {
    0% {
        transform: scale(1) rotate(0);
    }
    70% {
        transform: scale(2) rotate(360deg);
    }
    100% {
        transform: scale(6) rotate(-360);
    }
}

@keyframes flip {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes press {
    0% {
        transform: scale(1);
        box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.2);
    }
    70% {
        transform: scale(0.8);
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
    }
    100% {
        transform: scale(1);
        box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.2);
    }
}

@keyframes fade-out {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    70% {
        transform: translateX(50px);
        opacity: 0.3;
    }
    100% {
        transform: translateX(70px);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
