/******************************************************************
STYLESHEET: BTN

******************************************************************/

.btn {
    width: 70px;
    height: 70px;
    position: relative;
    display: inline-block;
    padding: 1em;
    margin: 0 1em;
    border-radius: 50%;
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    text-decoration: none;

    &:focus {
        box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.2);
        outline: none;
    }

    &:active,
    &:hover,
    &:visited {
        border: 0;
    }

    @include medium {
        width: 100px;
        height: 100px;
    }

    @include medium {
        &:hover {
            box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.2);
        }
    }

    &-wrapper {
        display: block;
        margin: 0 auto 3em;
        text-align: center;
    }

    &--mumbl {
        transform: translate(-1em, -2em);

        @include medium {
            transform: translate(0, 0);
        }
    }

    &--love {
        transform: translate(1em, -2em);

        @include medium {
            transform: translate(0, 0);
        }
    }

    &--favorites {
        position: absolute;
        top: 1em;
        right: 0.5em;
        box-shadow: none;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        z-index: 30;

        @include medium {
            top: 2em;
            right: 4.5em;

            &:hover {
                box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.2);
            }
        }
    }

    &--settings {
        position: absolute;
        top: 1em;
        left: 0.5em;
        box-shadow: none;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        z-index: 50;

        @include medium {
            top: 2em;
            left: 4.5em;

            &:hover {
                box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.2);
            }
        }
    }

    &--info {
        display: block;
        margin: 0 auto;
        width: 40px;
        height: 40px;

        @include medium {
            width: 50px;
            height: 50px;
        }
    }

    &__icon {
        width: 60%;
        height: 60%;
        position: absolute;
        top: 20%;
        left: 20%;
        right: 0;
        bottom: 0;
        color: $c-white;
        text-align: center;
        background-position: center center;
        background-size: cover;

        &--love {
            background-image: url('../img/icon-love.svg');
        }

        &--x {
            background-image: url('../img/icon-x.svg');
        }

        &--favorites {
            background-image: url('../img/icon-favorite.svg');
        }

        &--settings {
            background-image: url('../img/icon-settings.svg');
        }

        &--info {
            background-image: url('../img/icon-info.svg');
        }
    }

    @include medium {

        &.press {
            animation: press 0.2s cubic-bezier(0, 1.11, 1, 1);
        }
    }
}
