/******************************************************************
STYLESHEET: LINKS

******************************************************************/

a {
	font-family: $f-link;

    &:visited {

    }

    &:hover,
    &:focus {
    	
    }

    &:active {

    }
}