/******************************************************************
STYLESHEET: CONTENT

******************************************************************/

.content {
    width: 80%;
    margin: 0 auto;
    padding-top: 7em;

    @include medium {
        padding-top: 10em;
        min-height: 100vh;
    }

    @include large {
        padding-top: 13em;
    }
}