/******************************************************************
STYLESHEET: CARD

******************************************************************/

.card {
    color: $c-white;
    text-align: center;
    width: 17em;
    margin: 0 auto 2em;
    font-family: $f-heading;
    // box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    position: relative;
    perspective: 1000px;
    z-index: 20;

    @include medium {
        width: 20em;
    }

    @include large {
        margin: 0 auto 4em;
    }

    &:hover {
        // box-shadow: 1px 1px 70px rgba(0, 0, 0, 0.2);

        &:before {
            box-shadow: 1px 1px 70px rgba(0, 0, 0, 0.2);
        }
    }

    &:before {
        transform: rotateY(0deg);
        content: "";
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 80px;
        padding: 1em;
        border-radius: 50%;
        position: absolute;
        top: -40px;
        left: 50%;
        margin-left: -40px;
        background-color: transparent;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        transition: all 0.2s;
        z-index: -1;

        @include medium {
            width: 100px;
            height: 100px;
            top: -50px;
            margin-left: -50px;
        }
    }

    &.card-flip {

        .card__inner {
            transform: rotateY(-180deg);
        }

        &:before {
            transform: rotateY(-180deg);
        }
    }

    &__inner {
        // padding: 2em 0;
        border-radius: 5px;
        transition: all 0.2s;
        position: relative;
        height: 310px;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        transform-style: preserve-3d;

        @include medium {
            height: 400px;
        }

        &:hover {
            box-shadow: 1px 1px 70px rgba(0, 0, 0, 0.2);
        }
    }

    &__logo {
        width: 100%;
        height: 100%;

        &.rotate-in {
            animation: rotate-in 0.3s cubic-bezier(0, 1.11, 1, 1);
        }

        &.rotate-out {
            animation: rotate-out 0.3s cubic-bezier(0, 1.11, 1, 1);
        }

        &-wrapper {
            transition: all 0.2s;
            width: 80px;
            height: 80px;
            padding: 1em;
            border-radius: 50%;
            position: absolute;
            top: -40px;
            left: 50%;
            margin-left: -40px;
            z-index: 10;

            @include medium {
                width: 100px;
                height: 100px;
                top: -50px;
                margin-left: -50px;
            }
        }
    }

    &__info,
    &__name {
        backface-visibility: hidden;
        position: absolute;
        top: 100px;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 210px;

        @include medium {
            height: 300px;
        }
    }

    &__name {
        transform: perspective(1000px) rotateY(0deg);
        z-index: 2;
    }

    &__info {
        padding: 0 1.8em;
        transform: perspective(1000px) rotateY(180deg);
        font-size: 1.4rem;
        font-family: $f-base;
        font-weight: 700;
    }

    &__heading {
        padding: 0 30px;
        // margin: 0.5em 0;
        margin: 0 0 1em;
        font-size: 2rem;
        font-weight: 400;

        @include medium {
            // margin: 0.7em 0;
            font-size: 2.5rem;
        }

        @include large {
            // margin: 1em 0;
            font-size: 3rem;
        }
    }

    &__icon {
        width: 27px;
        height: 27px;
        display: inline-block;
        background-position: center center;
        background-size: cover;

        @include medium {
            width: 33px;
            height: 33px;
        }

        @include large {
            width: 40px;
            height: 40px;
        }

        &-wrapper {
            width: 100%;
            padding: 1.1em 0;
            transition: all 0.2s;
            position: absolute;
            bottom: 30px;
            left: 0;

            @include medium {
                bottom: 40px;
            }
        }
    }

    &__meaning {
        font-size: 1.1rem;
        font-weight: 300;
        width: 80%;
        transform: translate(-50%, -45%);
        position: absolute;
        top: 0;
        left: 50%;

        @include medium {
            font-size: 1.4rem;
            transform: translate(-50%, -25%);
        }
    }

    &__origin {

        &-wrapper {
            width: 100%;
            padding: 1.1em 1.4em;
            transition: all 0.2s;
            position: absolute;
            bottom: 30px;
            left: 0;
            font-size: 1.2rem;

            @include medium {
                bottom: 40px;
            }
        }
    }

    @include medium {

        &.flip {
            animation: flip 0.3s cubic-bezier(0, 1.11, 1, 1);
        }

        &.rotate {
            animation: rotate 0.3s cubic-bezier(0, 1.11, 1, 1);
        }

        &.discard {
            animation: discard 0.3s cubic-bezier(0, 1.11, 1, 1);
        }
    }
}